import {
  IFetchEventsFromOutletContract,
  IFetchEventsFromOutletRequest,
  IFetchEventsFromOutletResponse,
  IFetchEventsFromOutletBodyParams,
} from "./types";
import { http } from "../../http";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";

export class FetchEventsFromOutletError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchEventsFromOutletError");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchEventsFromOutlet(
  request: IFetchEventsFromOutletRequest
): Promise<IFetchEventsFromOutletResponse> {
  let response: AxiosResponse<IFetchEventsFromOutletContract>;

  try {
    const params: IFetchEventsFromOutletBodyParams = {
      starts_from: request.startsFrom,
    };

    response = await http.get(
      `/event-booking/outlet/${request.outletId}/events`,
      { params }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchEventsFromOutletError(error);
  }

  return response.data.data.length
    ? response.data.data.map((event) => ({
        id: event.id,
        startsAt: new Date(event.starts_at),
        name: event.title,
        termsConditions: event?.terms_conditions ?? undefined,
        hasTicketsAvailable: event.has_tickets_available,
        isBookingRequired: Boolean(event.is_booking_required),
      }))
    : [];
}

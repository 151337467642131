import {
  IEditReservationNotesPayload,
  IEditReservationNotesRequest,
} from "@/v2/repo/reservations/edit-reservation-notes";
import { IRepoErrors } from "@/v2/repo/errors";
import { http } from "../../http";

export class ReservationNotesEditError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: ReservationNotesEdit");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function editReservationNotes(
  request: IEditReservationNotesRequest
): Promise<void> {
  try {
    const params: IEditReservationNotesPayload = {
      special_requests: request.specialRequest,
    };

    await http.put(
      `/event-booking/outlet/${request.outletId}/reservations/${request.reservationId}`,
      {
        ...params,
      }
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new ReservationNotesEditError(e);
  }
}

import { http } from "../../http";
import {
  IFetchReservationsBodyParams,
  IFetchReservationsContract,
  IFetchReservationsRequest,
  IFetchReservationsResponse,
} from "./types";
import { AxiosResponse } from "axios";
export class FetchReservationsError extends Error {}

const stateMap: Record<
  IFetchReservationsContract["data"][0]["state"],
  IFetchReservationsResponse["data"][0]["state"]
> = {
  Rejected: "failed",
  Cancelled: "cancelled",
  Completed: "confirmed",
  Pending: "pending",
};

const sortKeyHandler = (
  sortBy: IFetchReservationsRequest["sort"]
): IFetchReservationsBodyParams["sort"] => {
  if (sortBy.field === "reference") {
    return sortBy.direction === "ASC" ? "reference" : "-reference";
  }

  if (sortBy.field === "eventName") {
    return sortBy.direction === "ASC" ? "title" : "-title";
  }

  if (sortBy.field === "eventDate") {
    return sortBy.direction === "ASC" ? "starts_at" : "-starts_at";
  }

  if (sortBy.field === "attendee") {
    return sortBy.direction === "ASC" ? "attendee_name" : "-attendee_name";
  }

  if (sortBy.field === "ticket") {
    return sortBy.direction === "ASC" ? "ticket_name" : "-ticket_name";
  }

  if (sortBy.field === "state") {
    return sortBy.direction === "ASC" ? "state" : "-state";
  }

  if (sortBy.field === "createdAt") {
    return sortBy.direction === "ASC" ? "created_at" : "-created_at";
  }

  return "-created_at";
};

export async function fetchReservations(
  request: IFetchReservationsRequest
): Promise<IFetchReservationsResponse> {
  let response: AxiosResponse<IFetchReservationsContract>;

  try {
    const params: IFetchReservationsBodyParams = {
      event_start_at_from: request.startDate.toISOString(),
      event_start_at_to: request.endDate.toISOString(),
      reference: request.reference?.length ? request.reference : undefined,
      attendee_phone_number: request.phoneNumber?.length
        ? `+${request.phoneNumber.replace(/\D/g, "")}`
        : undefined,
      page: request.page ?? 1,
      sort: sortKeyHandler(request.sort),
      states: [2, 3],
    };

    response = await http.get(
      `/event-booking/outlet/${request.outletId}/reservations`,
      { params }
    );

    return {
      data: response.data.data.length
        ? response.data.data.map((record) => ({
            id: record.id,
            reference: record.reference,
            notes: record.special_requests,
            attendee: {
              email: record.attendee.email,
              name: record.attendee.name,
              phoneNumber: record.attendee.phone_number,
            },
            createdAt: new Date(record.date),
            event: {
              title: record.ticket.event.title,
              startsAt: new Date(record.ticket.event.starts_at),
              finishesAt: new Date(record.ticket.event.finishes_at),
            },
            state: stateMap[record.state],
            ticket: {
              name: record.ticket.name,
              price: record.price,
            },
            spotReservationId: record?.table_reservation_id ?? undefined,
          }))
        : [],
      meta: {
        currentPage: response.data.meta.current_page,
        lastPage: response.data.meta.last_page,
        totalRecords: response.data.meta.total,
        perPage: response.data.meta.per_page,
      },
    };
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchReservationsError(e);
  }
}













































































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  ref,
  watch,
  reactive,
  computed,
  onMounted,
  PropType,
} from "@vue/composition-api";
import {
  AtomModal,
  AtomButton,
  AtomButtonSizeEnum,
  AtomText,
  AtomAutoSizeTextarea,
  AtomTextTypeEnum,
  AtomDropdown,
  MolFormControl,
  OrgFormInput,
  AtomInput,
} from "@/v2/new-design-system";
import {
  fetchEventsFromOutlet,
  IFetchEventsFromOutletResponse,
} from "@/v2/repo/reservations/fetch-events-from-outlet";
import {
  fetchTicketsFromEvent,
  IFetchTicketsFromEventResponse,
} from "@/v2/repo/reservations/fetch-tickets-from-event";
import { createReservation } from "@/v2/repo/reservations/create-reservation";
import { editReservationNotes } from "@/v2/repo/reservations/edit-reservation-notes";
import { ITableData } from "./types";
import { Toast } from "@/design-system";
import { toMoney, formValidation } from "@chatfood/core-utils";
import { report } from "@chatfood/bug-reporter";
import dayjs from "dayjs";
import { t } from "@/i18n";

const css = bemBuilder("reservations-create");

export default defineComponent({
  name: "ReservationsCreate",
  components: {
    AtomModal,
    AtomText,
    AtomButton,
    AtomDropdown,
    MolFormControl,
    OrgFormInput,
    AtomAutoSizeTextarea,
    AtomInput,
  },
  props: {
    outletId: {
      type: String,
      required: true,
    },
    reservation: {
      type: Object as PropType<ITableData>,
      default: null,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close", "create"],
  setup(props, { emit }) {
    const isSaving = ref(false);

    const formValues = reactive({
      name: "",
      email: "",
      phoneNumber: "",
      notes: "",
    });

    const errors = ref({});

    const formConfig = {
      email: {
        email: {
          message: t("module.reservations.create.invalid_email"),
        },
      },
      phoneNumber: {
        phoneNumber: {
          message: t("module.reservations.create.invalid_phone_number"),
        },
      },
    };

    const disabledForm = computed(
      () =>
        !formValues.name ||
        !formValues.email ||
        !formValues.phoneNumber ||
        !eventSelected.value ||
        !ticketSelected.value
    );

    const disabledTicketOption = computed(
      () => isSaving.value || !eventSelected.value?.id
    );

    function isFormValid() {
      errors.value = formValidation(formConfig, formValues);

      return !Object.keys(errors.value).length;
    }

    onMounted(() => {
      getEvents();
      if (props.isEditMode) {
        formValues.name = props.reservation.attendee;
        formValues.email = props.reservation.attendeeEmail;
        formValues.phoneNumber = props.reservation.attendeePhone;
        formValues.notes = props.reservation.notes;
      }
    });

    watch(
      () => formValues,
      () => {
        if (formValues.phoneNumber) {
          formValues.phoneNumber = `+${formValues.phoneNumber.replace(
            /\D+/g,
            ""
          )}`;
        }
      },
      { deep: true }
    );

    const events = ref<IFetchEventsFromOutletResponse>([]);
    const eventSelected = ref<IFetchEventsFromOutletResponse[0]>();

    async function getEvents() {
      try {
        const response = await fetchEventsFromOutlet({
          outletId: props.outletId,
          startsFrom: dayjs().startOf("day").toDate(),
        });

        events.value = response
          .filter(
            (reservation) =>
              reservation.hasTicketsAvailable && reservation.isBookingRequired
          )
          .sort((a, b) => a.startsAt.getTime() - b.startsAt.getTime())
          .map((reservation) => ({
            ...reservation,
            name: `${dayjs(reservation.startsAt).format(
              "DD/MM/YYYY HH:mm"
            )} - ${reservation.name}`,
          }));
      } catch (error) {
        report(error);
      }
    }

    function setEvent(event: IFetchEventsFromOutletResponse[0]) {
      eventSelected.value = event;

      getTickets();
    }

    const tickets = ref<IFetchTicketsFromEventResponse>([]);
    const ticketSelected = ref<IFetchTicketsFromEventResponse[0]>();

    async function getTickets() {
      if (!eventSelected.value) return;

      try {
        const response = await fetchTicketsFromEvent({
          outletId: props.outletId,
          eventId: eventSelected.value.id,
        });

        tickets.value = response
          .filter((ticket) => ticket.isAvailable)
          .map((ticket) => ({
            ...ticket,
            name: `${ticket.name} - ${toMoney(
              ticket.price.value,
              ticket.price.currency
            )}`,
          }));
      } catch (error) {
        report(error);
      }
    }

    function setTicket(ticket: IFetchTicketsFromEventResponse[0]) {
      ticketSelected.value = ticket;
    }

    async function onUpdateReservationNotes() {
      isSaving.value = true;
      try {
        const request = {
          outletId: props.outletId,
          reservationId: props.reservation.uniqueId,
          specialRequest: formValues.notes,
        };
        await editReservationNotes(request);
        emit("create");
        new Toast().create({
          type: "success",
          text: t("module.reservations.create.update_success_desc"),
        });
      } catch (e) {
        report(e);
      } finally {
        isSaving.value = false;
      }
    }

    async function onCreateReservation() {
      if (!ticketSelected.value || !isFormValid()) return;

      isSaving.value = true;

      try {
        await createReservation({
          outletId: props.outletId,
          ticketId: ticketSelected.value.id,
          name: formValues.name,
          email: formValues.email,
          phoneNumber: formValues.phoneNumber,
          notes: formValues.notes,
        });

        emit("create");

        new Toast().create({
          type: "success",
          text: t("module.reservations.create.toast_success_desc"),
        });
      } catch (error) {
        report(error);
      } finally {
        isSaving.value = false;
      }
    }

    function onClose() {
      emit("close");
    }

    return {
      t,
      css,
      onClose,
      formValues,
      events,
      setEvent,
      eventSelected,
      tickets,
      setTicket,
      ticketSelected,
      isSaving,
      errors,
      disabledForm,
      disabledTicketOption,
      onCreateReservation,
      AtomTextTypeEnum,
      AtomButtonSizeEnum,
      onUpdateReservationNotes,
    };
  },
});

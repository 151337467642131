import { http } from "../../http";
import {
  IFetchSpotsAvailableRequest,
  IFetchSpotsAvailableContract,
  IFetchSpotsAvailableResponse,
  IFetchSpotsAvailableBodyParams,
} from "./types";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "@/v2/repo/errors";

export class FetchSpotsAvailableError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchSpotsAvailable");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchSpotsAvailable(
  request: IFetchSpotsAvailableRequest
): Promise<IFetchSpotsAvailableResponse> {
  let response: AxiosResponse<IFetchSpotsAvailableContract>;

  try {
    const params: IFetchSpotsAvailableBodyParams = {
      starts_at: request.startsAt,
      finishes_at: request.finishesAt,
    };

    response = await http.get(
      `api/v3/outlets/${request.outletId}/tables/available-for-reservation`,
      { params }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchSpotsAvailableError(error);
  }

  return response.data.data.length
    ? response.data.data.map((spot) => ({
        id: spot.id,
        name: spot.name,
        seats: spot?.seats ?? 0,
        group: spot?.grouping_label ?? undefined,
      }))
    : [];
}

import {
  ICreateReservationBodyParams,
  ICreateReservationRequest,
} from "./types";
import { http } from "../../http";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";

export class CreateReservationError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: CreateReservationError");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function createReservation(
  request: ICreateReservationRequest
): Promise<boolean> {
  let response: AxiosResponse;

  try {
    const params: ICreateReservationBodyParams = {
      attendee_name: request.name,
      attendee_email: request.email,
      attendee_phone_number: request.phoneNumber,
      special_requests: request.notes.length ? request.notes : undefined,
      quantity: 1,
    };

    response = await http.post(
      `/event-booking/outlet/${request.outletId}/tickets/${request.ticketId}/make-manual-reserve`,
      params
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new CreateReservationError(error);
  }

  return Boolean(response);
}

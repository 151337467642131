var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.css()},[_c('div',{class:_vm.css('header')},[_c('AtomText',{attrs:{"type":_vm.AtomTextTypeEnum.H1}},[_vm._v(" "+_vm._s(_vm.t("module.reservations.main.title"))+" ")]),_c('OutletSwitcher',{class:_vm.css('outlet-switcher'),attrs:{"business-id":_vm.businessId,"outlet-id":_vm.outletId}})],1),_c('OrgFilters',{class:_vm.css('filters'),attrs:{"config":_vm.filtersConfig},on:{"apply":_vm.onApplyFilters,"clear":_vm.onClearFilters}}),_c('div',{class:_vm.css('ctas')},[_c('AtomButton',{attrs:{"on-click":function () { return (_vm.showReservationModal = true); }}},[_vm._v(" "+_vm._s(_vm.t("module.reservations.main.create_reservation"))+" ")])],1),_c('div',[_c('OrgTable',{attrs:{"loading":_vm.isLoadingList,"columns":_vm.columns,"total-records":_vm.pagination.perPage,"data":_vm.tableData,"on-sort":_vm.onTableSort,"no-records-msg":_vm.t('module.reservations.main.table_no_data'),"has-actions":""},scopedSlots:_vm._u([{key:"reference",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_vm._v(_vm._s(cell))])]}},{key:"eventName",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_vm._v(_vm._s(cell))])]}},{key:"eventDate",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_c('AtomDate',{attrs:{"datetime":cell,"type":"custom","format":"DD/MM/YY HH:mm"}})],1)]}},{key:"attendee",fn:function(ref){
var cell = ref.cell;
var row = ref.row;
return [_c('AtomText',[_vm._v(_vm._s(cell))]),_c('AtomText',{attrs:{"tag":_vm.AtomTextTagEnum.DIV,"type":_vm.AtomTextTypeEnum.SMALL,"color":_vm.AtomTextColorEnum.RAINY}},[_vm._v(" "+_vm._s(row.attendeeEmail)+" ")]),_c('AtomText',{attrs:{"tag":_vm.AtomTextTagEnum.DIV,"type":_vm.AtomTextTypeEnum.SMALL,"color":_vm.AtomTextColorEnum.RAINY}},[_vm._v(" "+_vm._s(_vm.formattedPhone(row.attendeePhone))+" ")])]}},{key:"ticket",fn:function(ref){
var cell = ref.cell;
var row = ref.row;
return [_c('AtomText',[_vm._v(_vm._s(cell))]),_c('AtomText',{attrs:{"tag":_vm.AtomTextTagEnum.DIV,"type":_vm.AtomTextTypeEnum.SMALL}},[_c('AtomMoney',{attrs:{"amount":row.ticketPrice.value,"currency":row.ticketPrice.currency}})],1)]}},{key:"createdAt",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_c('AtomDate',{attrs:{"datetime":cell,"type":"custom","format":"DD/MM/YY HH:mm"}})],1)]}},{key:"state",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_vm._v(_vm._s(_vm.translateState(cell)))])]}},{key:"spot",fn:function(ref){
var row = ref.row;
return [(row.state === 'confirmed')?_c('div',[(!row.spotReservationId)?_c('AtomButton',{class:_vm.css('table-btn'),attrs:{"size":_vm.AtomButtonSizeEnum.SMALL,"on-click":function () { return _vm.assignSpot(row); }}},[_vm._v(" "+_vm._s(_vm.t("module.reservations.main.table_assign_spot"))+" ")]):_c('AtomText',[_vm._v(" "+_vm._s(_vm.t("module.reservations.main.table_assigned"))+" ")])],1):_vm._e()]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('MolTableAction',{attrs:{"tooltip-text":_vm.t('module.reservations.main.action_edit'),"icon-name":"pencil","type":_vm.MolTableActionTypeEnum.DANGER,"on-click":function () { return _vm.showReservationModalEditMode(row); }}}),(row.state === 'confirmed')?_c('MolTableAction',{attrs:{"tooltip-text":_vm.t('module.reservations.main.action_cancel'),"icon-name":"delete","type":_vm.MolTableActionTypeEnum.DANGER,"on-click":function () { return _vm.showCancelModal(row.uniqueId, row.reference, row.attendee); }}}):_vm._e()]}}])}),(_vm.tableData.length)?_c('MolPagination',{class:_vm.css('pagination'),attrs:{"current-page":_vm.pagination.currentPage,"last-page":_vm.pagination.lastPage,"total":_vm.pagination.totalRecords,"on-navigate":_vm.handlePagination,"per-page":_vm.pagination.perPage}}):_vm._e()],1),(_vm.cancelModal)?_c('MolModalConfirm',{attrs:{"title":_vm.t('module.reservations.main.cancel_title', {
        reference: _vm.cancelData.reference,
      }),"label":_vm.t('module.reservations.main.cancel_message', {
        attendee: _vm.cancelData.attendee,
      }),"display-confirm-icon":false,"confirm-button-label":_vm.t('module.reservations.main.cancel_proceed'),"on-click-cancel":function () { return (_vm.cancelModal = false); },"on-click-confirm":function () { return _vm.askCancelReservation(_vm.cancelData.id); }}}):_vm._e(),(_vm.showReservationModal)?_c('ReservationsCreate',{attrs:{"outlet-id":_vm.outletId,"reservation":_vm.reservationSelected,"is-edit-mode":_vm.isReservationModalEditMode},on:{"create":_vm.createdReservation,"close":_vm.onCloseReservationModal}}):_vm._e(),(_vm.showAssignSpotModal)?_c('ReservationsAssignSpot',{attrs:{"outlet-id":_vm.outletId,"starts-at":_vm.reservationSelected.eventDate,"finishes-at":_vm.reservationSelected.eventFinishesAt,"on-assign":_vm.onAssignSpot},on:{"close":_vm.closeAssignSpotModal},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{class:_vm.css('assign-spot-header')},[_c('AtomText',{attrs:{"type":_vm.AtomTextTypeEnum.H3}},[_vm._v(" "+_vm._s(_vm.t("module.reservations.main.assign_spot"))+" ")]),_c('AtomText',{attrs:{"type":_vm.AtomTextTypeEnum.BODY_BOLD}},[_c('AtomDate',{attrs:{"type":"custom","datetime":_vm.reservationSelected.eventDate,"format":"ddd, D MMM YYYY - h:mma"}})],1)],1),(_vm.reservationSelected.eventName || _vm.reservationSelected.ticket)?_c('div',{class:_vm.css('assign-spot-details')},[_c('AtomText',[_c('strong',[_vm._v(_vm._s(_vm.reservationSelected.eventName)+":")]),_vm._v(" "+_vm._s(_vm.reservationSelected.ticket)+" ")])],1):_vm._e()]},proxy:true}],null,false,90517953)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
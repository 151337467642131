





















































































































































































import { t } from "@/i18n";
import {
  AtomDate,
  AtomMoney,
  AtomButton,
  AtomButtonSizeEnum,
  AtomText,
  AtomTextColorEnum,
  AtomTextTagEnum,
  AtomTextTypeEnum,
  IOrgFiltersConfigProps,
  IOrgTableProps,
  MolModalConfirm,
  MolPagination,
  MolTableAction,
  MolTableActionTypeEnum,
  OrgFilters,
  OrgTable,
} from "@/v2/new-design-system";
import { OutletSwitcher } from "@/v2/components/outlet-switcher";
import {
  fetchReservations,
  IFetchReservationsRequest,
  IFetchReservationsResponse,
} from "@/v2/repo/reservations/fetch-reservations";
import { Toast } from "@/design-system";
import { ReservationsCreate } from "../create";
import { ReservationsAssignSpot } from "../assign-spot";
import { cancelReservation } from "@/v2/repo/reservations/cancel-reservation";
import { bemBuilder } from "@/v2/util/bem-builder";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { ITableData } from "@/v2/module/reservations/create";
import { report } from "@chatfood/bug-reporter";
import { allocateSpot } from "@/v2/repo/spots-occupancy/allocate-spot";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import { parsePhoneNumber, getNumberFrom } from "awesome-phonenumber";

const css = bemBuilder("reservations-main");

export default defineComponent({
  name: "ReservationsMain",
  components: {
    OutletSwitcher,
    MolTableAction,
    AtomText,
    AtomButton,
    OrgTable,
    AtomDate,
    AtomMoney,
    MolPagination,
    MolModalConfirm,
    OrgFilters,
    ReservationsCreate,
    ReservationsAssignSpot,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const reservations = ref<IFetchReservationsResponse["data"]>([]);
    const currentPage = ref(1);
    const pagination = ref<IFetchReservationsResponse["meta"]>({
      currentPage: 1,
      lastPage: 1,
      totalRecords: 0,
      perPage: 10,
    });
    const isLoadingList = ref(false);

    const cancelModal = ref(false);
    const cancelData = ref({
      id: "",
      reference: "",
      attendee: "",
    });

    const showCancelModal = (
      id: string,
      reference: string,
      attendee: string
    ) => {
      cancelModal.value = true;
      cancelData.value = {
        id,
        reference,
        attendee,
      };
    };

    const translateState = (
      state: IFetchReservationsResponse["data"][0]["state"]
    ) => {
      const mapStates: Record<string, string> = {
        pending: t("module.reservations.main.state_pending"),
        failed: t("module.reservations.main.state_failed"),
        cancelled: t("module.reservations.main.state_cancelled"),
      };

      return mapStates[state] || t("module.reservations.main.state_confirmed");
    };

    const filtersConfig = ref<IOrgFiltersConfigProps>({
      date: {
        name: t("module.reservations.main.filter_event_date_label"),
        type: "date-range",
        defaultRange: 0,
      },
      reference: {
        name: t("module.reservations.main.filter_reference_label"),
        type: "input-text",
      },
      phoneNumber: {
        name: t("module.reservations.main.filter_phone_number_label"),
        type: "input-text",
      },
    });

    const currentFilters = ref({
      date: { start: new Date(), end: new Date() },
      reference: "",
      phoneNumber: "",
    });

    type ISort = {
      key: IFetchReservationsRequest["sort"]["field"];
      direction: IFetchReservationsRequest["sort"]["direction"];
    };

    const currentSort = ref<ISort>({
      key: "createdAt",
      direction: "DESC",
    });

    const onTableSort = (
      key: ISort["key"],
      direction: ISort["direction"] | "NONE"
    ) => {
      const isSameCurrentSorting =
        currentSort.value.key === key &&
        currentSort.value.direction === direction;
      if (direction === "NONE" || isSameCurrentSorting) {
        return;
      }

      currentSort.value = { key, direction };
      refreshReservations(1);
    };

    const askCancelReservation = async (reservationId: string) => {
      try {
        await cancelReservation({
          id: reservationId,
          outletId: props.outletId,
        });

        refreshReservations(currentPage.value, true);
      } catch (e) {
        report(e);
        generalErrorToast();
      } finally {
        cancelModal.value = false;
      }
    };

    const columns = computed<IOrgTableProps["columns"]>(() => ({
      reference: {
        header: () => t("module.reservations.main.table_reference"),
        show: true,
        width: 120,
        sortable: true,
        sortDirection: "NONE",
      },
      eventName: {
        header: () => t("module.reservations.main.table_event_name"),
        show: true,
        width: 200,
        sortable: true,
        sortDirection: "NONE",
      },
      eventDate: {
        header: () => t("module.reservations.main.table_event_date"),
        show: true,
        width: 130,
        sortable: true,
        sortDirection: "NONE",
      },
      attendee: {
        header: () => t("module.reservations.main.table_attendee_name"),
        show: true,
        width: 120,
        sortable: true,
        sortDirection: "NONE",
      },
      ticket: {
        header: () => t("module.reservations.main.table_ticket_title"),
        show: true,
        width: 120,
        sortable: true,
        sortDirection: "NONE",
      },
      createdAt: {
        header: () => t("module.reservations.main.table_created_at"),
        show: true,
        width: 120,
        sortable: true,
        sortDirection: "NONE",
      },
      state: {
        header: () => t("module.reservations.main.table_state"),
        show: true,
        sortable: true,
        sortDirection: "NONE",
      },
      spot: {
        header: () => t("module.reservations.main.table_spot"),
        show: true,
        sortable: false,
        sortDirection: "NONE",
      },
    }));

    const tableData = computed<ITableData[]>(() =>
      reservations.value.map((reservation) => ({
        reference: reservation.reference,
        eventName: reservation.event.title,
        uniqueId: reservation.id,
        eventDate: reservation.event.startsAt,
        eventFinishesAt: reservation.event.finishesAt,
        state: reservation.state,
        ticket: reservation.ticket.name,
        notes: reservation.notes,
        ticketPrice: reservation.ticket.price,
        attendee: reservation.attendee.name,
        attendeeEmail: reservation.attendee.email,
        attendeePhone: reservation.attendee.phoneNumber,
        createdAt: reservation.createdAt,
        spotReservationId: reservation.spotReservationId,
      }))
    );

    async function refreshReservations(
      page: number,
      skipLoading: boolean = false
    ) {
      if (!skipLoading) isLoadingList.value = true;

      try {
        const response = await fetchReservations({
          businessId: props.businessId,
          outletId: props.outletId,
          startDate: currentFilters.value.date.start,
          endDate: currentFilters.value.date.end,
          reference: currentFilters.value.reference,
          phoneNumber: currentFilters.value.phoneNumber,
          page,
          sort: {
            field: currentSort.value.key,
            direction: currentSort.value.direction,
          },
        });

        currentPage.value = page;
        reservations.value = response.data;
        pagination.value = response.meta;
      } catch (e) {
        report(e);
        reservations.value = [];
        generalErrorToast();
      } finally {
        isLoadingList.value = false;
      }
    }

    watch(
      () => props.outletId,
      () => {
        refreshReservations(1);
      },
      { immediate: true }
    );

    function handlePagination(page: number) {
      refreshReservations(page);
    }

    function defineFilters(newFilters: any) {
      currentFilters.value = {
        date: newFilters.date,
        reference: newFilters.reference,
        phoneNumber: newFilters.phoneNumber,
      };
    }

    function onClearFilters(newFilters: any) {
      defineFilters(newFilters);
      refreshReservations(1);
    }

    function onApplyFilters(newFilters: any) {
      defineFilters(newFilters);
      refreshReservations(1);
    }

    function onCloseReservationModal() {
      showReservationModal.value = false;

      if (isReservationModalEditMode) {
        isReservationModalEditMode.value = false;
      }
    }

    const showReservationModal = ref(false);

    function createdReservation() {
      showReservationModal.value = false;
      refreshReservations(1);
    }

    const showAssignSpotModal = ref(false);
    const reservationSelected = ref<ITableData | null>(null);

    async function assignSpot(reservation: ITableData) {
      await (reservationSelected.value = reservation);
      showAssignSpotModal.value = true;
    }

    function closeAssignSpotModal() {
      reservationSelected.value = null;
      showAssignSpotModal.value = false;
    }

    async function onAssignSpot(spotId: string) {
      if (!reservationSelected.value?.uniqueId) return;

      try {
        await allocateSpot({
          outletId: props.outletId,
          reservationId: reservationSelected.value?.uniqueId,
          spotId,
        });

        new Toast().create({
          type: "success",
          text: t("module.reservations.main.success_desc_assigned"),
        });

        refreshReservations(1);
      } catch (e: any) {
        report(e);

        const mapErrors: Record<string, string> = {
          event_booking_reservation_already_allocated_to_table: t(
            "module.reservations.main.error_already_allocated_to_table"
          ),
          event_booking_table_already_reserved: t(
            "module.reservations.main.error_table_already_reserved"
          ),
          event_booking_invalid_reservation_state: t(
            "module.reservations.main.error_invalid_reservation_state"
          ),
        };

        new Toast().create({
          type: "error",
          text:
            mapErrors[e?.errors?.errorCode] ??
            t("module.reservations.main.error_generic"),
        });
      } finally {
        closeAssignSpotModal();
      }
    }

    const isReservationModalEditMode = ref(false);

    function showReservationModalEditMode(reservation: ITableData) {
      reservationSelected.value = reservation;
      showReservationModal.value = true;
      isReservationModalEditMode.value = true;
    }

    function formattedPhone(phone: string) {
      const phoneNumber = parsePhoneNumber(phone);
      if (phoneNumber.valid) {
        return getNumberFrom(phoneNumber, "international").number;
      }

      return phone;
    }

    return {
      t,
      css,
      columns,
      onTableSort,
      tableData,
      pagination,
      isLoadingList,
      translateState,
      formattedPhone,
      handlePagination,
      AtomTextTagEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
      MolTableActionTypeEnum,
      showCancelModal,
      askCancelReservation,
      cancelData,
      cancelModal,
      onClearFilters,
      onApplyFilters,
      filtersConfig,
      currentFilters,
      showReservationModal,
      isReservationModalEditMode,
      createdReservation,
      onCloseReservationModal,
      showAssignSpotModal,
      showReservationModalEditMode,
      assignSpot,
      onAssignSpot,
      reservationSelected,
      closeAssignSpotModal,
    };
  },
});

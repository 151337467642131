import {
  IFetchTicketsFromEventContract,
  IFetchTicketsFromEventRequest,
  IFetchTicketsFromEventResponse,
} from "./types";
import { http } from "../../http";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";

export class FetchTicketsFromEventError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchTicketsFromEventError");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchTicketsFromEvent(
  request: IFetchTicketsFromEventRequest
): Promise<IFetchTicketsFromEventResponse> {
  let response: AxiosResponse<IFetchTicketsFromEventContract>;

  try {
    response = await http.get(
      `/event-booking/outlet/${request.outletId}/events/${request.eventId}/list-tickets`
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchTicketsFromEventError(error);
  }

  return response.data.data.length
    ? response.data.data.map((ticket) => ({
        id: ticket.id,
        name: ticket.name,
        description: ticket?.description ?? undefined,
        price: ticket.price,
        isAvailable: ticket.is_available,
      }))
    : [];
}

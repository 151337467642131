import { http } from "../../http";
import { ICancelReservationRequest } from "./types";

export class CancelReservationError extends Error {}

export async function cancelReservation(
  request: ICancelReservationRequest
): Promise<boolean> {
  try {
    await http.post(
      `/event-booking/outlet/${request.outletId}/cancel-reservation/${request.id}`
    );

    return true;
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new CancelReservationError(e);
  }
}

import { IAllocateSpotRequest, IAllocateSpotBodyParams } from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { AxiosResponse } from "axios";
import { http } from "../../http";

export class AllocateSpotError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: AllocateSpot");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function allocateSpot(
  request: IAllocateSpotRequest
): Promise<boolean> {
  let response: AxiosResponse;

  try {
    const params: IAllocateSpotBodyParams = {
      table: request.spotId,
    };

    response = await http.post(
      `event-booking/outlet/${request.outletId}/reservations/${request.reservationId}/allocate-table`,
      { ...params }
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const customError = e?.response?.data?.error;
    if (customError) {
      e.errorCode = customError?.code;
    }

    throw new AllocateSpotError(e);
  }

  return Boolean(response);
}

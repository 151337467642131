


















































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  PropType,
  ref,
  onMounted,
  computed,
} from "@vue/composition-api";
import {
  AtomLoading,
  AtomModal,
  AtomIcon,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomButton,
  AtomButtonSizeEnum,
  MolMultiselect,
} from "@/v2/new-design-system";
import {
  fetchSpotsAvailable,
  IFetchSpotsAvailableResponse,
} from "@/v2/repo/spots-occupancy/fetch-spots-available";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { report } from "@chatfood/bug-reporter";
import { t } from "@/i18n";

const css = bemBuilder("reservations-assign-spot");

export default defineComponent({
  name: "ReservationsAssignSpot",
  components: {
    AtomLoading,
    AtomModal,
    AtomIcon,
    AtomButton,
    AtomText,
    MolMultiselect,
  },
  props: {
    outletId: {
      type: String,
      required: true,
    },
    startsAt: {
      type: Date,
      required: true,
    },
    finishesAt: {
      type: Date,
      required: true,
    },
    spotLabelCta: {
      type: String,
      default: "",
    },
    onAssign: {
      type: Function as PropType<(spotId: string) => void>,
      required: true,
    },
  },
  emits: ["close"],
  setup(props, { emit, slots }) {
    const isLoading = ref(false);
    const isAssigningSpotId = ref("");

    const spots = ref<IFetchSpotsAvailableResponse>([]);

    const groupFilter = ref();
    const seatsFilter = ref();

    const hasSlot = computed(() => Boolean(slots.header));

    onMounted(() => getSpots());

    async function getSpots() {
      isLoading.value = true;

      try {
        const response = await fetchSpotsAvailable({
          outletId: props.outletId,
          startsAt: props.startsAt,
          finishesAt: props.finishesAt,
        });

        spots.value = response.sort((a, b) => a.name.localeCompare(b.name));
      } catch (e) {
        report(e);
        generalErrorToast();
      } finally {
        isLoading.value = false;
      }
    }

    const filteredSpots = computed(() => {
      const filterGroups = spots.value.filter((group) => {
        if (!groupFilter.value || groupFilter.value.id === "all") {
          return spots.value;
        }

        if (groupFilter.value.id === "others") {
          return (
            group.group === undefined || group.group.toLowerCase() === "others"
          );
        }

        return group.group === groupFilter.value.name;
      });

      const filterSeats = filterGroups.filter((group) => {
        if (!seatsFilter.value || seatsFilter.value.id === "all") {
          return filterGroups;
        }

        return group.seats === seatsFilter.value.name;
      });

      return filterSeats;
    });

    const groups = computed(() => {
      const mapGroups = spots.value
        .map((group) => {
          return {
            id: group?.group?.length ? group.group : "others",
            name: group?.group?.length
              ? group.group
              : t("module.reservations.assign_spot.others"),
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter(
          (group, index, array) =>
            array.findIndex((g) => g.id === group.id) === index
        );

      return [
        {
          id: "all",
          name: t("module.reservations.assign_spot.all_groups"),
        },
        ...mapGroups,
      ];
    });

    const seats = computed(() => {
      const mapSeats = spots.value
        .map((group) => {
          return {
            id: group.seats ?? 0,
            name: group.seats ?? 0,
          };
        })
        .sort((a, b) => a.name - b.name)
        .filter(
          (group, index, array) =>
            array.findIndex((g) => g.id === group.id) === index
        );

      return [
        {
          id: "all",
          name: t("module.reservations.assign_spot.all_seats"),
        },
        ...mapSeats,
      ];
    });

    async function assignSpot(spotId: string) {
      if (isAssigningSpotId.value) return;

      isAssigningSpotId.value = spotId;

      await props.onAssign(spotId);

      isAssigningSpotId.value = "";
      onClose();
    }

    function onClose() {
      emit("close");
    }

    return {
      t,
      css,
      spots,
      seats,
      groups,
      hasSlot,
      onClose,
      groupFilter,
      seatsFilter,
      isLoading,
      assignSpot,
      filteredSpots,
      isAssigningSpotId,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
    };
  },
});
